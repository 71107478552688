import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    /**
     * NOTE: can't migrate composite component
     */
    hostAPI.registerComponent(
      'VerticalMenu',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/ComboBoxInputNavigation/ComboBoxInputNavigation.skin' /* webpackChunkName: "VerticalMenu_ComboBoxInputNavigation" */
          ),
          import(
            './viewer/VerticalMenuComboBox.controller' /* webpackChunkName: "VerticalMenu_ComboBoxInputNavigation" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'ComboBoxInputNavigation',
    );
  },
};

export default entry;
