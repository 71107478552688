import { IThunderboltEntry } from '@wix/editor-elements-types';

const thenReturnDefault = (componentModule: any) => {
  return {
    component: componentModule.default,
  };
};

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/skinComps/WrapColumn/WrapColumn.skin' /* webpackChunkName: "StylableHorizontalMenu_wrap_column" */
        ).then(thenReturnDefault);
      },
      'wrap_column',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/skinComps/WrapFlyout/WrapFlyout.skin' /* webpackChunkName: "StylableHorizontalMenu_wrap_flyout" */
        ).then(thenReturnDefault);
      },
      'wrap_flyout',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/skinComps/ScrollColumn/ScrollColumn.skin' /* webpackChunkName: "StylableHorizontalMenu_scroll_column" */
        ).then(thenReturnDefault);
      },
      'scroll_column',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/skinComps/ScrollFlyout/ScrollFlyout.skin' /* webpackChunkName: "StylableHorizontalMenu_scroll_flyout" */
        ).then(thenReturnDefault);
      },
      'scroll_flyout',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/skinComps/WrapColumn/WrapColumn.skin' /* webpackChunkName: "StylableHorizontalMenu_WrapColumn" */
        ).then(thenReturnDefault);
      },
      'WrapColumn',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/skinComps/WrapFlyout/WrapFlyout.skin' /* webpackChunkName: "StylableHorizontalMenu_WrapFlyout" */
        ).then(thenReturnDefault);
      },
      'WrapFlyout',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/skinComps/ScrollColumn/ScrollColumn.skin' /* webpackChunkName: "StylableHorizontalMenu_ScrollColumn" */
        ).then(thenReturnDefault);
      },
      'ScrollColumn',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/skinComps/ScrollFlyout/ScrollFlyout.skin' /* webpackChunkName: "StylableHorizontalMenu_ScrollFlyout" */
        ).then(thenReturnDefault);
      },
      'ScrollFlyout',
    );
  },
};

export default entry;
