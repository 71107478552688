import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    /**
     * TODO: can't migrate? Composite Component
     */
    hostAPI.registerComponent(
      'DropDownMenu',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/ComboBoxInputNavigation/ComboBoxInputNavigation.skin' /* webpackChunkName: "DropDownMenu_ComboBoxInputNavigation" */
          ),
          import(
            './viewer/DropDownMenuComboBox.controller' /* webpackChunkName: "DropDownMenu_ComboBoxInputNavigation" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'ComboBoxInputNavigation',
    );
  },
};

export default entry;
